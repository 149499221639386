import $ from 'jquery';
import throttle from 'lodash/throttle';

export default class Header {
  constructor(Ui) {
    this.Ui = Ui;
    this.$node = $('#header-wrapper');
    this.$corporateLinks = $('#show-corporate-links');
    initCorporateLinks.call(this);
    initStickyHeader.call(this);
  }

  getHeaderHeight() {
    return this.$node.height();
  }
}

/**
 * Copy from ui/ui.js
 */
function initCorporateLinks() {
  this.$corporateLinks.click(function() {
    $(this).toggleClass('active');
    $('#corporate-links').toggleClass('active');
    $('#show-corporate-links-icon').toggleClass('active');
  });
}

/**
 * Copy from ui/ui.js->handleStickyHeader()
 */
function initStickyHeader() {
  this.Ui.$window.on('scroll.Header.stickyHeader', throttle(
    () => {
      const pixelScrolled = this.Ui.$window.scrollTop();
      const corporateLinksHeight = this.$corporateLinks.height();
      const totalHeight = this.getHeaderHeight() + corporateLinksHeight;

      if (pixelScrolled > totalHeight) {
        this.Ui.$body.addClass('sticky-sm-down sticky-md-up');
      } else {
        this.Ui.$body.removeClass('sticky-sm-down sticky-md-up');
      }
    },
    125,
  ));
}
