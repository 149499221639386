export const gridBreakpoints = {
  xs: 0,
  sm: 540,
  md: 740,
  lg: 1020,
  xl: 1440,
};

export const gridGutterWidth = 20;

export const deviceIsMinLarge = window.matchMedia(
  `only screen and (min-width: ${gridBreakpoints.lg}px)`);

export const deviceIsMinMd = window.matchMedia(
  `only screen and (min-width: ${gridBreakpoints.md}px)`);

export const MediaQueries = {
  deviceIsMinSM: window.matchMedia(
    `only screen and (min-width: ${gridBreakpoints.sm}px)`
  ),
  deviceIsMinMD: window.matchMedia(
    `only screen and (min-width: ${gridBreakpoints.md}px)`
  ),
  deviceIsMinLG: window.matchMedia(
    `only screen and (min-width: ${gridBreakpoints.lg}px)`
  ),
  deviceIsMinXL: window.matchMedia(
    `only screen and (min-width: ${gridBreakpoints.xl}px)`
  ),
}
