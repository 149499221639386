import $ from 'jquery';
import Video from './Video';
// Modified version with export
import {objectFitVideos} from '../lib/object-fit-videos';

$('.ef-video').each(function(index, video) {
  new Video($(video));
});

// Polyfill CSS object-fit for videos in IE11
objectFitVideos();
