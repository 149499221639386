import $ from 'jquery';

/**
 * Copy from nm/ui/offcanvas.js
 */
var bodyDOM = document.getElementsByTagName('body')[0];
var doc = document;

class OffCanvas {
  constructor() {
    this.mobilePanels = {
      els: [
        'panel-offcanvas-wrapper',
        'content-wrapper',
        'panel-search-wrapper'
      ],
      contentPanel: 1,
      currentIndex: 1,
      oldIndex: null,
    };
    this.lastToggle = null;

    this.init();
  }

  init() {
    $(document).on('click', '.panel-closer', () => {
      this.showPanel(this.mobilePanels.contentPanel);
    });
  }

  showPanelById(id, $toggle) {
    this.showPanel(this.getPanelById(id), $toggle);
  }

  getPanelById(id) {
    for (var i = 0; i < this.mobilePanels.els.length; i++)
      if (this.mobilePanels.els[i] == id)
        return i;
  }

  showPanel(index, $toggle) {
    var $currentToggle = $toggle || false;
    var $lastToggle = this.$lastToggle;
    var isLastAvailableAndNotCurrentToggle = $lastToggle && $lastToggle.length > 0 && $currentToggle.length && $lastToggle.get(0) !== $currentToggle.get(0);
    $('.quicknavigation-item-wrapper').removeClass('active');

    for (var i = 0; i < this.mobilePanels.els.length; i++) {
      bodyDOM.classList.remove(this.mobilePanels.els[i].replace('-wrapper', '') + '-active');
    }

    bodyDOM.classList.add('panel-active');
    bodyDOM.classList.add(this.mobilePanels.els[index].replace('-wrapper', '') + '-active');

    if (index == this.mobilePanels.currentIndex) {
      //deactivate = show content panel
      if (index != this.mobilePanels.contentPanel) {
        this.showPanel(this.mobilePanels.contentPanel);
      } else {
        bodyDOM.classList.remove('panel-active');
        bodyDOM.classList.remove(this.mobilePanels.els[this.mobilePanels.contentPanel].replace('-wrapper', '') + '-active');

        if (this.$lastToggle) {
          this.setToggleAriaExpanded(this.$lastToggle, false);
        }
      }
    } else {
      // Show panel

      //remove unnecessary classes
      var panelDOM = doc.getElementById(this.mobilePanels.els[index]);
      var panelCurrentDOM = doc.getElementById(this.mobilePanels.els[this.mobilePanels.currentIndex]);
      $(panelDOM).removeClass('slideToLeft slideToRight slideFromLeft slideFromRight animation-finished');

      if (index < this.mobilePanels.currentIndex) {
        //show
        $(panelDOM).addClass('slideFromLeft');
        //hide
        $(panelCurrentDOM).addClass('slideToRight');
      } else {
        //show
        $(panelDOM).addClass('slideFromRight');
        //hide
        $(panelCurrentDOM).addClass('slideToLeft');
      }

      this.mobilePanels.oldIndex = this.mobilePanels.currentIndex;
      var panelOldDOM = doc.getElementById(this.mobilePanels.els[this.mobilePanels.oldIndex]);
      setTimeout(function() {
        $(panelOldDOM).addClass('animation-finished');
      }, 500);

      this.mobilePanels.currentIndex = index;

      if (index == this.mobilePanels.contentPanel) {
        // If no other panel is opened and the panel is closing completely
        bodyDOM.classList.remove('panel-active');
        bodyDOM.classList.remove(this.mobilePanels.els[this.mobilePanels.contentPanel].replace('-wrapper', '') + '-active');

        if ($lastToggle) {
          this.setToggleAriaExpanded($lastToggle, false);
        }

        // Timeout to wait for animation to be finished
        setTimeout(function() {
          // Move focus to body till it's possible to move it to related trigger button
          var $focusTarget = $('#header-wrapper');

          if ($lastToggle) {
            $focusTarget = $lastToggle;
          }

          $focusTarget.focus();
        }, 500);
      } else {
        if ($currentToggle) {
          this.setToggleAriaExpanded($currentToggle, true);
        }
        if (isLastAvailableAndNotCurrentToggle) {
          this.setToggleAriaExpanded($lastToggle, false);
        }
        // Move focus to current panel
        $(panelDOM).focus();
      }
    }

    this.$lastToggle = $currentToggle;

    if ($currentToggle) {
      updateActiveState.call(this, $currentToggle);
    }
    if (isLastAvailableAndNotCurrentToggle) {
      updateActiveState.call(this, $lastToggle);
    }
  }

  collapseCategoryLinks() {
    $('.nav-brandinformation').on('click', function(e) {
      e.preventDefault();

      var $this = $(this);
      $this.toggleClass('active');
      $this.next('.offcanvas-collapse').toggleClass('active');
    });
  }


  /**
   * @param {HTMLBaseElement} $toggle
   * @return {string}
   */
  getToggleAriaExpanded($toggle) {
    return $toggle.attr('aria-expanded');
  }

  /**
   * @param {HTMLBaseElement} $toggle
   * @param {bool} state
   */
  setToggleAriaExpanded($toggle, state) {
    $toggle.attr('aria-expanded', state.toString());
  }
}

function updateActiveState($toggle) {
  if(this.getToggleAriaExpanded($toggle) === 'true') {
    $toggle.addClass('is-active');
  } else {
    $toggle.removeClass('is-active');
  }
}

export default (new OffCanvas());
