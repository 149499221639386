import {isCountryAt} from './utilities/country';

const configDe = {
  homePageURL_DE: 'https://www.ernstings-family.de',
  homePageURL_AT: 'https://www.ernstings-family.at',
  imageDirectoryPath: '',
  socialFeedUrl: 'https://api.ernstings-family.com/socialfeed/?eID=read_v1&channel=allChannels&numberItems=2',
  svgDirectoryPath: '',
};

const configAt = {
  socialFeedUrl: 'https://api.ernstings-family.com/socialfeed/?eID=read_v1&channel=allChannels&numberItems=2&country=at',
};

const config = (() => {
  let tempConfig = {};

  // window.EfConfig is declared as inline-script in project/Configuration/TypoScript/Setup/page.typoscript
  if (window.EfConfig) {
    tempConfig = Object.assign({}, configDe, window.EfConfig);
  }

  if (isCountryAt) {
    tempConfig =  Object.assign({}, tempConfig, configAt);
  }

  return tempConfig;
})();

export default config;
