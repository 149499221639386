import $ from 'jquery';
import 'is-in-viewport';
import throttle from 'lodash/throttle';
import {isIE} from '../utilities/user-agent';
import {MediaQueries} from '../utilities/media-queries';

export default class Video {
  constructor($video) {
    this.$video = $video;
    this.video = $video[0];
    this.$videoParent = $video.parent();
    this.$videoSource = $video.find('.ef-video-source');
    this.$videoPlayButtonWrapper = this.$videoParent.siblings('.teaser-video-play-button-wrapper');
    this.$videoPlayButton = this.$videoPlayButtonWrapper.find('.btn-video-play');
    this.$videoControlsWrapper = this.$videoParent.siblings('.teaser-video-control-bar-wrapper');
    this.$videoControlPlayBtn = this.$videoControlsWrapper.find('.ef-video-btn-play');
    this.$videoCurrentTime = this.$videoControlsWrapper.find('.ef-video-current-time');
    this.$videoProgressbar = this.$videoControlsWrapper.find('.ef-video-progress');
    this.$videoMuted = this.$videoControlsWrapper.find('.ef-video-volume-control');
    this.$videoVolume = this.$videoControlsWrapper.find('.ef-video-volume');
    this.$videoLoadingWrapper = this.$videoParent.siblings('.ef-loading-wrapper');
    this.$videoFullscreenBtn = this.$videoControlsWrapper.find('.ef-video-fullscreen-wrapper');
    this.$videoQualityWrapper = this.$videoControlsWrapper.find('.ef-video-quality-wrapper');
    this.$videoQualityBtn = this.$videoControlsWrapper.find('.ef-video-quality-wrapper');
    this.viewportForHDSource = this.$video.data('video-hd-viewport') || false;
    this.hasAutoplay = this.$video.hasClass('ef-video-autoplay');
    this.hasLoop = this.$video.hasClass('ef-video-loop');
    this.hasLooped = false;

    _setSourceBasedOnViewport.call(this);
    _initAutoplay.call(this);
    _initVideoEventHandler.call(this);
    _initVideoPlayButtonEventHandler.call(this);
    _initVideoControls.call(this);
    _initFailedVideoEventHandler.call(this);
  }

  playVideo() {
    _addTimings.call(this);
    this.$videoParent.removeClass('could-not-load');
    this.$videoPlayButtonWrapper.removeClass('ended').addClass('played');
    this.$videoControlsWrapper.addClass('played');
    this.$video.removeClass('paused');
    this.video.play();
    this.$video.addClass('started');
    this.$video.addClass('has-played');
  }

  /**
   * Ensures that the correct Video Source is loaded
   * when it was switched.
   */
  loadAndPlayVideo() {
    var self = this;
    var videoCurrentTime = this.video.currentTime;
    var isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    var isSafari = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;

    /* global detectIE11 */
    if (isIE || isiOS || isSafari) {
      this.video.load();
      this.$video.on('canplay.loadAndPlayVideo', function() {
        self.video.currentTime = videoCurrentTime;
        self.$video.off('canplay.loadAndPlayVideo');
      });
      this.playVideo();
    } else {
      this.video.load();
      this.video.currentTime = videoCurrentTime;
      this.playVideo();
    }
  }

  pauseVideo() {
    this.video.pause();
    this.$video.addClass('paused');
    this.$videoPlayButtonWrapper.removeClass('played');
    this.$videoControlsWrapper.removeClass('played');
  }
}

function _setSourceBasedOnViewport() {
  if (this.viewportForHDSource) {
    var self = this;
    var isInit = true;
    var deviceIsInMinDefinedViewport = MediaQueries['deviceIsMin' + this.viewportForHDSource];

    __handleViewport(deviceIsInMinDefinedViewport);
    deviceIsInMinDefinedViewport.addListener(__handleViewport.bind(this));
  }

  function __handleViewport(deviceIsInMinDefinedViewport) {
    var deviceShouldSwitchSource = deviceIsInMinDefinedViewport.matches;
    var isVideoPlaying = self.video.paused === false;

    if (isInit && deviceShouldSwitchSource) {
      _toggleVideoSource.call(self);
    }
    if (isInit === false) {
      if (isVideoPlaying === true) {
        _switchVideoSource.call(self);
      } else {
        _toggleVideoSource.call(self);
      }
    }
    isInit = false;
  }
}

function _initAutoplay() {
  if (this.hasAutoplay) {
    var self = this;
    var autoplayTolerance = -400;
    var givenAutoplayTolerance = this.$video.data('autoplay-tolerance');

    // Override tolerance if set for this video
    if (typeof givenAutoplayTolerance !== 'undefined' && givenAutoplayTolerance !== '') {
      autoplayTolerance = givenAutoplayTolerance;
    }

    _playIfInView.call(self, autoplayTolerance);

    $(window).on('scroll.initVideoAutoplay', throttle(function() {
      _playIfInView.call(self, autoplayTolerance);
    }));
  }
}

function _playIfInView(autoplayTolerance) {
  if (this.$video.is(':in-viewport('+ autoplayTolerance + ')') && !this.$video.hasClass('has-played')) {
    this.$video.attr('autoplay', true);
    this.loadAndPlayVideo();
  }
}

function _initVideoEventHandler() {
  var self = this;
  // Set default layout
  this.video.volume = 0.5;

  this.$video.on('click', function() {
    if (_isVideoPaused.call(self) || self.video.ended) {
      self.playVideo();
    } else if (self.video.paused) {
      self.loadAndPlayVideo();
    } else {
      self.pauseVideo();
    }

    return false;
  });

  // Set states
  this.$video.on('ended', function() {
    if(self.hasLoop) {
      self.hasLooped = true;
      // Manually loop video, because 'ended' event isn't triggered for videos with loop attribute
      self.playVideo();
    } else {
      self.$videoPlayButtonWrapper.removeClass('played').addClass('ended');
    }
  });
  this.$video.on('playing', function() {
    self.$videoPlayButtonWrapper.removeClass('ended');
    self.$videoLoadingWrapper.removeClass('waiting');
  });
  this.$video.on('waiting', function() {
    // Prevent loading animation when video is looped, since the sources are already loaded
    if(self.hasLooped === false) {
      self.$videoLoadingWrapper.addClass('waiting');
    }
  });
}

function _initVideoPlayButtonEventHandler() {
  var self = this;

  this.$videoPlayButton.on('click', function() {
    if (_isVideoPaused.call(self) || self.video.ended) {
      self.playVideo();
    } else if (self.video.paused) {
      self.loadAndPlayVideo();
    } else {
      self.pauseVideo();
    }
    return false;
  });
}

function _initVideoControls() {
  var self = this;

  // Handle click on Play/Pause control
  this.$videoControlPlayBtn.on('click', function() {
    if (_isVideoPaused.call(self) || self.video.ended) {
      self.playVideo();
    } else if (self.video.paused) {
      self.loadAndPlayVideo();
    } else {
      self.pauseVideo();
    }
    return false;
  });

  // Handle update of HTML5 video current play time
  this.$video.on('timeupdate', function() {
    var videoDurationTime = self.video.duration;
    var videoCurrentTime = self.video.currentTime;
    var currentMinutes = Math.floor(videoCurrentTime / 60);
    var currentSeconds = Math.floor(videoCurrentTime);
    var currentSecondsRemaining = currentSeconds - (currentMinutes * 60);

    self.$videoCurrentTime.empty();
    self.$videoCurrentTime.text(_formatTime(currentMinutes) + ':' + _formatTime(currentSecondsRemaining));

    // Show HTML5 video current play time on progressbar
    var videoProgress = 100 * videoCurrentTime / videoDurationTime || 0; // in %
    self.$videoProgressbar.val(videoProgress);
  });

  // Handle timeline backwards/forwards drag
  var timeDrag = false;

  this.$videoProgressbar.mousedown(function(e) {
    timeDrag = true;
    __updateProgressbar(e.pageX);
  });
  $(document).mouseup(function(e) {
    if (timeDrag) {
      timeDrag = false;
      __updateProgressbar(e.pageX);
    }
  });
  $(document).mousemove(function(e) {
    if (timeDrag) {
      __updateProgressbar(e.pageX);
    }
  });

  // Handle click on Mute/Unmute control
  this.$videoMuted.on('click', function() {
    self.$videoControlsWrapper.toggleClass('muted');
    self.video.muted = !self.video.muted;
    return false;
  });

  // Handle volume up/down drag
  this.$videoVolume.on('mousedown', function(e) {
    var videoVolume = self.$videoVolume;
    var position = e.pageX - videoVolume.offset().left;
    var percentage = 100 * position / videoVolume.width();

    self.$videoVolume.val(percentage);
    self.video.volume = percentage / 100;
  });

  // Handle click on Fullscreen control
  var dontSupportRequestFullscreen = !(this.video.requestFullscreen || this.video.msRequestFullscreen || this.video.mozRequestFullScreen || this.video.mozRequestFullScreen || this.video.webkitRequestFullscreen);
  var dontSupportWebkitEnterFullscreen = !(this.video.webkitEnterFullscreen || false);

  if (dontSupportRequestFullscreen && dontSupportWebkitEnterFullscreen) {
    this.$videoControlsWrapper.addClass('disabled-fullscreen-option');
  }

  this.$videoFullscreenBtn.on('click', function() {
    if (self.video.requestFullscreen) {
      if (document.fullScreenElement) {
        document.cancelFullScreen();
      } else {
        self.video.requestFullscreen();
      }
      // IE 11
    } else if (self.video.msRequestFullscreen) {
      if (document.msFullscreenElement) {
        document.msExitFullscreen();
      } else {
        self.video.msRequestFullscreen();
      }
      // Firefox
    } else if (self.video.mozRequestFullScreen) {
      if (document.mozFullScreenElement) {
        document.mozCancelFullScreen();
      } else {
        self.video.mozRequestFullScreen();
      }
      // Chrome and Safari
    } else if (self.video.webkitRequestFullscreen) {
      if (document.webkitFullscreenElement) {
        document.webkitCancelFullScreen();
      } else {
        self.video.webkitRequestFullscreen();
      }
      // iOS
    } else if (self.video.webkitEnterFullscreen) {
      if (document.webkitFullscreenElement) {
        document.webkitCancelFullScreen();
      } else {
        self.video.webkitEnterFullscreen();
      }
    }
    return false;
  });

  // Handle video seeking
  this.$video.on('seeking', function() {
    if (self.hasLooped === false) {
      self.$videoPlayButtonWrapper.removeClass('played');
    }
    self.$videoPlayButtonWrapper.removeClass('ended');
    self.$videoControlsWrapper.removeClass('played');
  });

  // Handle video seeked
  this.$video.on('seeked', function() {
    self.playVideo();
    self.$videoLoadingWrapper.removeClass('waiting');
  });

  // Handle click on Quality control
  this.$videoQualityBtn.on('click', function(e) {
    e.preventDefault();
    _switchVideoSource.call(self);
  });

  var __updateProgressbar = function(x) {
    var progress = self.$videoProgressbar;
    var videoDurationTime = self.video.duration;
    var position = x - progress.offset().left; // Click position
    var percentage = 100 * position / progress.width();

    // Check within range
    if (percentage > 100) {
      percentage = 100;
    }
    if (percentage < 0) {
      percentage = 0;
    }

    // Update progress bar and video current time
    self.$videoProgressbar.val(percentage);
    self.video.currentTime = videoDurationTime * percentage / 100;
  };
}

function _switchVideoSource() {
  _toggleVideoSource.call(this);
  this.loadAndPlayVideo();
}

function _toggleVideoSource() {
  var videoHDSource = this.$videoSource.data('video-hd');
  var videoSDSource = this.$videoSource.data('video-sd');

  this.$videoSource.toggleClass('hd-quality-active');
  this.$videoQualityWrapper.toggleClass('hd-quality-active');

  if (this.$videoSource.hasClass('hd-quality-active')) {
    this.$videoSource.attr('src', videoHDSource);
  } else {
    this.$videoSource.attr('src', videoSDSource);
  }
}

function _initFailedVideoEventHandler() {
  var self = this;

  self.$video.next('.ef-video-error').on('click', function() {
    self.loadAndPlayVideo();
  });
}

/**
 * We can't rely on HTMLMediaElement.paused alone, since the
 * HTMLMediaElement.paused property returns true if the media is paused,
 * which is any time the video is not playing, including when it is set
 * at 0 duration after it first loads.
 *
 * @see https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Client-side_web_APIs/Video_and_audio_APIs#Playing_and_pausing_the_video
 */
function _isVideoPaused() {
  return this.$video.hasClass('paused');
}

function _addTimings(timerCountUp, $givenVideoTimeHolder) {
  var self = this;
  var $videoTimeHolder = $givenVideoTimeHolder || this.$videoControlsWrapper.find('.ef-video-progress-time');
  var timerCounter = 1;

  if ($videoTimeHolder.length < 1) {
    return;
  }

  if (timerCountUp) {
    timerCounter += timerCountUp;
  }

  if (this.video.readyState > 0) {
    var videoDurationTime = this.video.duration;
    var durationMinutes = Math.floor(videoDurationTime / 60);
    var durationSeconds = Math.floor(videoDurationTime);
    var durationSecondsRemaining = durationSeconds - (durationMinutes * 60);

    // Set video duration
    $videoTimeHolder.children('.ef-video-duration-time').text(_formatTime(durationMinutes) + ':' + _formatTime(durationSecondsRemaining));

    // Show video timing
    $videoTimeHolder.addClass('visible');
  } else if (timerCounter < 20) { // 10 Seconds
    // Retry
    setTimeout(function() {
      _addTimings.call(self, timerCounter++, $videoTimeHolder);
    }, 500);
  } else {
    this.$video.removeClass('started');
    this.$videoParent.addClass('could-not-load');
    this.$videoLoadingWrapper.removeClass('waiting');
    this.$videoPlayButtonWrapper.removeClass('played');
    this.$videoControlsWrapper.removeClass('played');
  }
}

function _formatTime(time) {
  var timeString = time.toString();
  if (timeString.length < 2) {
    timeString = '0' + timeString;
  }
  return timeString;
}
