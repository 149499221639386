import $ from 'jquery';
import './campaign/Tracking';
import 'jquery-colorbox';

export default class Campaign {
  constructor() {
    (async () => {
      /*
       * Init forms
       */
      const $contestApplicationForm = $('#form-contestApplication');
      if ($contestApplicationForm.length) {
        const {ContestApplicationForm} = await import(/* webpackChunkName: "ContestApplicationForm" */ './campaign/ContestApplicationForm');
        new ContestApplicationForm({
          $form: $contestApplicationForm,
        });
      }

      if ($('#form-vote').length) {
        const {VoteForm} = await import(/* webpackChunkName: "VoteForm" */ './campaign/VoteForm');

        // Move vote form to modal
        const $voteFormContent = $('#vote-form-container');
        const $voteFormModalBody = $('#modal-photo-contest-vote .modal-body');
        if ($voteFormContent.length && $voteFormModalBody.length) {
          $voteFormContent.detach();
          $voteFormModalBody.append($voteFormContent.html());
        }

        // Show vote form modal only if necessary
        const $photoContestVoteCheck = $('#photo-contest-vote-check');
        if ($photoContestVoteCheck.length) {
          let isChecked = $photoContestVoteCheck.prop('checked');
          $(document).on('change', $photoContestVoteCheck, function() {
            if (isChecked) {
              isChecked = false;
            } else {
              $('#modal-photo-contest-vote').modal({
                backdrop: 'static',
              });
              isChecked = true;
            }
          });
        }

        new VoteForm({
          $form: $('#form-vote'), // reselect on purpose, since the form is moved within the DOM
        });
      }
    })();

    /*
     * Show modal on load only if necessary
     */
    var $modalOpenOnload = $( ".modal-open-onload" );
    if ( $modalOpenOnload.length ) {
      $modalOpenOnload.modal();
    }

    /*
     * Gallery
     */
    var $blockWinner = $('#block-winner');

    //shuffle
    $.each($blockWinner.find('div.row'), function(index, el) {
      var $el = $(el);
      var $find = $el.children();

      $find.sort(function() {
        return 0.5 - Math.random();
      });

      $el.empty();
      $find.appendTo($el).css('visibility', 'visible');
    });

    if ($blockWinner.length) {
      var $galleryItems = $blockWinner.find( ".block-gallery__item" );
      $galleryItems.colorbox( {
        rel: "contest-gallery",
        opacity: 0.6,
        loop: false,
        maxWidth: "99%",
        maxHeight: "99%",
        current: "",
        previous: "<span class='sr-only'>vorheriges Bild</span>",
        next: "<span class='sr-only'>nächstes Bild</span>",
        close: "<span class='sr-only'>schließen</span>"
      });
    }
  }
}
