import 'bootstrap/js/dist/modal';
import $ from 'jquery';

class Modal {
  constructor() {
    _moveModalsToBody();
  }
}

function _moveModalsToBody() {
  $.each($('.modal'), function(index, modal) {
    if ($(modal).parent().get(0).tagName !== 'BODY') {
      $('body').append(modal);
    }
  });
}

export default (new Modal());
